<template>
  <form
    class="form box-shadow"
    @submit.prevent
    @click="stopPropag"
    :style="{ width: `${width}px` }"
  >
    <Header v-if="headline !== ''" :headline="headline" />

    <div v-if="showInputs" class="form-body">
      <FormInput
        v-for="input of inputs"
        :key="input.id"
        :input="input"
        @update="$emit('update', $event)"
      />
    </div>

    <slot></slot>

    <ButtonArea
      v-if="showBtnArea"
      :id="id"
      :deleteIcon="deleteIcon"
      :deleteBtn="deleteBtn"
      @cancel="$emit('cancel')"
      @submit="$emit('submit')"
      @delete="$emit('delete')"
    />

    <Steps
      v-if="steps.length > 0"
      :steps="steps"
      :currentStep="currentStep"
      @updateStep="$emit('updateStep', $event)"
    />
  </form>
</template>

<script>
import Header from './Header.vue'
import FormInput from './FormInput.vue'
import ButtonArea from './ButtonArea.vue'
import Steps from './Steps.vue'

import { computed } from 'vue'

export default {
  name: 'Form',
  components: { Header, FormInput, ButtonArea, Steps },
  props: {
    headline: { type: String, default: '' },
    id: { type: String, default: '' },
    showBtns: { type: Boolean, default: true },
    deleteIcon: { type: Boolean, default: false },
    deleteBtn: { type: Boolean, default: false },
    showInputs: { type: Boolean, default: true },
    width: { type: Number, default: 700 },
    steps: { type: Array, default: [] },
    currentStep: { type: Number, default: 0 },
    inputs: {
      type: Array,
      default: [
        {
          label: '',
          type: 'text',
          options: [],
          value: '',
          key: '',
          property: '',
          required: false,
          placeholder: '',
          icon: '',
          width: '50',
          step: 1,
          values: [],
          selected: [],
          max: 100,
          allowNull: true,
        },
      ],
    },
  },
  emits: ['save', 'cancel', 'updateStep'],
  setup(props) {
    const showBtnArea = computed(() => {
      if (props.showBtns && props.steps.length > 0) {
        if (props.currentStep + 1 === props.steps.length) return true
        else return false
      } else if (props.showBtns) {
        return true
      } else {
        return false
      }
    })

    return {
      showBtnArea,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.form {
  max-width: calc(95% - 30px);
  padding: 15px;
  border-radius: 15px;
  margin: 70px auto;
  background-color: var(--base);
  z-index: 10;

  h3 {
    padding-left: 5px;
  }

  .form-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }

  .btn-area {
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-end;

    button:last-of-type {
      margin-left: 5px;
    }
  }
}
</style>
