export default [
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('../Authentication.vue'),
    meta: { unAuthenticated: true },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@modules/authentication/views/Login.vue'),
        meta: {
          title: 'Sharedex | Welcome Back!',
        },
      },
      {
        path: 'reset-password',
        name: 'Reset Password',
        component: () =>
          import('@modules/authentication/views/ResetPassword.vue'),
        meta: {
          title: 'Sharedex | Reset Password',
        },
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@modules/authentication/views/Register.vue'),
        meta: {
          title: 'Sharedex | Register Account',
        },
      },
      {
        path: 'verify',
        name: 'Verify',
        component: () => import('@modules/authentication/views/Verify.vue'),
        meta: {
          title: 'Sharedex | Verification',
        },
      },
      {
        path: 'demo',
        name: 'Demo',
        component: () => import('@modules/authentication/views/Demo.vue'),
        meta: {
          title: 'Sharedex | Start Testing',
        },
      },
    ],
  },
]
