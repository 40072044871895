<template>
  <div class="desktop-navbar row">
    <router-link v-if="mobile" to="/" class="logo row">
      <i class="fa-solid fa-circle-nodes"></i>
    </router-link>

    <div
      v-on="
        mobile
          ? { click: () => toggleDropdown(true) }
          : {
              mouseenter: () => toggleDropdown(true),
              mouseleave: () => toggleDropdown(false),
            }
      "
      class="profile-button highlight row"
    >
      <div class="icon">
        <i class="fa-solid fa-user"></i>
      </div>

      <span>{{ t('Profile') }}</span>
    </div>

    <transition name="fade">
      <ProfileDropdown
        v-if="showDropdown"
        @show="toggleDropdown($event)"
        :showDropdown="showDropdown"
      />
    </transition>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'

import ProfileDropdown from './ProfileDropdown.vue'

export default {
  name: 'DesktopDashboard',
  components: { ProfileDropdown },
  setup() {
    const store = useStore()
    const showDropdown = ref(false)
    const hideTimeout = ref(null)

    const mobile = computed(() => {
      return store.state.screen.device === 'mobile'
    })

    const toggleDropdown = (show) => {
      if (mobile.value) {
        showDropdown.value = show
        return
      }

      if (show) {
        showDropdown.value = show
        clearTimeout(hideTimeout.value)
        hideTimeout.value = null
      }

      hideTimeout.value = setTimeout(() => {
        showDropdown.value = show
      }, 300)
    }

    return {
      mobile,
      showDropdown,
      toggleDropdown,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.desktop-navbar {
  width: calc(100% - 40px);
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.1rem;
  padding: 0 20px;
  height: 54px;
  max-height: 54px;
  border-bottom: 1px solid var(--dark-2);

  @media #{$mobile} {
    width: calc(100% - 20px);
    padding: 0 10px;
  }

  .logo {
    font-size: 2.25rem;
    position: absolute;
    left: calc(50% - 1.125rem);
  }

  .profile-button {
    cursor: pointer;
    border-radius: 15px;
    padding: 5px 12px;

    .icon {
      position: relative;

      @media #{$mobile} {
        font-size: 1.7rem;
      }
    }

    span {
      margin-left: 7px;

      @media #{$mobile} {
        display: none;
      }
    }
  }
}
</style>
