import { createApp } from 'vue'
import { MotionPlugin } from '@vueuse/motion'
import HighchartsVue from 'highcharts-vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import App from './App.vue'
import global from '@lib/global.js'

import authentication from '@modules/authentication'
import dashboard from '@modules/dashboard'
import settings from '@modules/settings'
import status from '@modules/status'
import time from '@modules/time'

const app = createApp(App)

app.use(MotionPlugin)
app.use(HighchartsVue)
app.use(store)
app.use(router)
app.use(global)

app.mount('#app')
