import { translate } from './translate.js'
import { stopPropag, upperCase, deepEquality } from './helper.js'
import { hasPermission } from './auth.js'

export default {
  install: (app) => {
    app.config.globalProperties.t = translate
    app.config.globalProperties.upperCase = upperCase
    app.config.globalProperties.deepEquality = deepEquality
    app.config.globalProperties.stopPropag = stopPropag
    app.config.globalProperties.hasPermission = hasPermission
  },
}
