import router from '../../../router/index.js'
import { io } from 'socket.io-client'

export default {
  state() {
    return {
      socket: null,
    }
  },

  mutations: {
    clearStorage(state, rootState) {
      localStorage.clear()

      rootState.user.id = null
      rootState.user.organization = null
      rootState.user.email = null
      rootState.token = null

      state.socket = null

      router.replace('/auth/login')
    },
  },

  actions: {
    autoLogin({ commit, rootState }) {
      const token = localStorage.getItem('token')
      const expirationDate = localStorage.getItem('tokenExpirationDate')
      const user = JSON.parse(localStorage.getItem('user'))

      if (!user || !token || !expirationDate) return

      rootState.user = user
      rootState.token = token
      rootState.expirationDate = expirationDate

      let time = expirationDate - new Date().getTime()

      if (time <= 600000) {
        commit('clearStorage', rootState)
        commit('showNotification', {
          type: 'info',
          message: 'Logged out automatically.',
        })
        return
      }

      setTimeout(async () => {
        commit('clearStorage', rootState)
        commit('showNotification', {
          type: 'info',
          message: 'Logged out automatically.',
        })
      }, time)
    },

    /*
      ROUTE /auth/:userId?
    */
    logout({ commit, rootState }) {
      commit('showNotification', {
        type: 'info',
        message: 'User successfully logged out!',
      })

      commit('clearStorage', rootState)
    },

    async addOrganization({ commit, rootState }, user) {
      const url = `${rootState.url}/auth`
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success && data.userId) {
        commit('showNotification', { type: 'success', message: data.success })
        router.push(`/auth/verify?user=${data.userId}`)
      }
    },

    async login({ commit, rootState, state }, user) {
      const url = `${rootState.url}/auth`
      const response = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success && data.user && data.token) {
        commit('showNotification', {
          type: 'info',
          message: data.success,
        })

        commit('setTimeFilter', { type: 'id', value: data.user.id })

        const expirationDate = new Date().getTime() + 12 * 60 * 60000

        rootState.token = data.token
        rootState.expirationDate = expirationDate
        rootState.user = data.user

        localStorage.setItem('token', data.token)
        localStorage.setItem('tokenExpirationDate', expirationDate)
        localStorage.setItem('user', JSON.stringify(data.user))

        state.socket = io(rootState.url, {
          extraHeaders: {
            organization: rootState.user.organization,
          },
        })

        router.replace('/dashboard')
      }
    },

    /*
      ROUTE /reset-password/:email
    */
    async resetPassword({ commit, rootState }, email) {
      const url = `${rootState.url}/reset-password/${email}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', {
          type: 'success',
          message: data.success,
        })

        return true
      }

      return false
    },

    /*
      ROUTE /verify-account/:userId
    */
    async verifyAccount({ commit, rootState }, payload) {
      const url = `${rootState.url}/verify-account/${payload.userId}/${payload.verificationCode}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', {
          type: 'success',
          message: data.success,
        })

        return true
      }
    },

    /*
      ROUTE /email-availability/:email
    */
    async emailAvailabilty({ rootState }, email) {
      const url = `${rootState.url}/email-availability/${email}`
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })

      const data = await response.json()

      if (data.success) return true

      return false
    },
  },

  getters: {},
}
