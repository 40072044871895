export default {
  'Welcome Back': 'Welcome Back',
  "Don't have an account?": "Don't have an account?",
  'Register Organization': 'Register Organization',
  '(min. 4 letters)': '(min. 4 letters)',
  'Already have an account?': 'Already have an account?',
  'Reset Password': 'Reset Password',
  'Verify Account': 'Verify Account',
  'Verification Code': 'Verification Code',
  'Account already verified?': 'Account already verified?',
  Register: 'Register',
  Password: 'Password',
  'Forgot password': 'Forgot password',
  'Enter your email address': 'Enter your email address',
  Submit: 'Submit',
  Login: 'Login',
  Organization: 'Organization',
  'Min. char': 'Min. char',
  Uppercase: 'Uppercase',
  Number: 'Number',
  Special: 'Special',
  'I have read and accept the Terms & Conditions':
    'I have read and accept the Terms & Conditions',
  'I have read and accept the Privacy policy':
    'I have read and accept the Privacy policy',
  Start: 'Start',
  Email: 'Email',
  'demo-01':
    'Please note: All data generated in a demo account will not be stored permanently.',
  'Sign up': 'Sign up',
  Login: 'Login',
  Language: 'Language',
  German: 'German',
  English: 'English',
  'Legal notice': 'Legal notice',
  'Data privacy': 'Data privacy',
  'Contact us': 'Contact us',
  Verify: 'Verify',
  'Select language': 'Select language',
}
