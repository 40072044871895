import store from '../store/index.js'

const translation = {
  en: {
    // UI Components
    Delete: 'Delete',
    Cancel: 'Cancel',
    Add: 'Add',
    Export: 'Export',
    Edit: 'Edit',
    Delete: 'Delete',
    Search: 'Search',
    'Show more': 'Show more',
    'Show less': 'Show less',
    'rotation-warning':
      'Please rotate your device to portrait mode to ensure full functionality',
    'Select all': 'Select all',
    'Deselect all': 'Deselect all',
    'Your trial has expired': 'Your trial has expired',
    'If you need assistance, please': 'If you need assistance, please',
    'contact us': 'contact us',
    'Please contact your organization administrator.':
      'Please contact your organization administrator.',

    // Notifications
    'Key successfully updated.': 'Key successfully updated.',
    'Successfully updated user status.': 'Successfully updated user status.',
    'User successfully logged out!': 'User successfully logged out!',
    'Welcome! Please verify your email address.':
      'Welcome! Please verify your email address.',
    'Welcome back!': 'Welcome back!',
    'Password reset. Please check your inbox.':
      'Password reset. Please check your inbox.',
    'User already verified. Please login.':
      'User already verified. Please login.',
    'Email successfully verified!': 'Email successfully verified!',
    'Email available.': 'Email available.',
    'Welcome! Feel free to look around.': 'Welcome! Feel free to look around.',
    'Entry successfully added.': 'Entry successfully added.',
    'Schedule successfully updated.': 'Schedule successfully updated.',
    'Entry successfully deleted.': 'Entry successfully deleted.',
    'Organization successfully updated.': 'Organization successfully updated.',
    'Organization successfully deleted.': 'Organization successfully deleted.',
    'Team successfully created.': 'Team successfully created.',
    'Team successfully updated.': 'Team successfully updated.',
    'Team successfully deleted.': 'Team successfully deleted.',
    'Role successfully created.': 'Role successfully created.',
    'Role successfully updated.': 'Role successfully updated.',
    'Role successfully deleted.': 'Role successfully deleted.',
    'Task successfully created.': 'Task successfully created.',
    'Task successfully updated.': 'Task successfully updated.',
    'Task successfully deleted.': 'Task successfully deleted.',
    'Status successfully updated.': 'Status successfully updated.',
    'Time entry successfully added.': 'Time entry successfully added.',
    'Time entry successfully updated.': 'Time entry successfully updated.',
    'Entry successfully deleted.': 'Entry successfully deleted.',
    'Status successfully updated.': 'Status successfully updated.',
    'Entry successfully deleted.': 'Entry successfully deleted.',
    'File successfully uploaded.': 'File successfully uploaded.',
    'User successfully registered.': 'User successfully registered.',
    'User successfully deleted.': 'User successfully deleted.',
    'Email successfully updated.': 'Email successfully updated.',
    'Permission successfully updated.': 'Permission successfully updated.',
    'Password successfully updated.': 'Password successfully updated.',
    'Note successfully updated.': 'Note successfully updated.',
    'Time successfully updated.': 'Time successfully updated.',
    'Basic data successfully updated.': 'Basic data successfully updated.',
    'Messengers successfully updated.': 'Messengers successfully updated.',
    'Position successfully updated.': 'Position successfully updated.',

    'Something went wrong. Please try again.':
      'Something went wrong. Please try again.',
    'Missing email address.': 'Missing email address.',
    'Status object incomplete.': 'Status object incomplete.',
    'Email already associated with an organization.':
      'Email already associated with an organization.',
    'User does not exist.': 'User does not exist.',
    'Please verify your email address. An email has been sent.':
      'Please verify your email address. An email has been sent.',
    'Username and password do not match.':
      'Username and password do not match.',
    'Verification failed. Please try again.':
      'Verification failed. Please try again.',
    'End cannot be before start date.': 'End cannot be before start date.',
    'Task is archived. Please reactivate.':
      'Task is archived. Please reactivate.',
    'Task is ongoing. Please stop task first.':
      'Task is ongoing. Please stop task first.',
    'Please cancel your subscription before proceeding.':
      'Please cancel your subscription before proceeding.',
    'Team with that name already exists.':
      'Team with that name already exists.',
    'Team has associated users.': 'Team has associated users.',
    'Role with that name already exists.':
      'Role with that name already exists.',
    'Role has associated users.': 'Role has associated users.',
    'Task with that name already exists.':
      'Task with that name already exists.',
    'Missing filter parameters. Please try again.':
      'Missing filter parameters. Please try again.',
    'Reached max. amount of users in your plan.':
      'Reached max. amount of users in your plan.',
    'User already part of an organization.':
      'User already part of an organization.',
    'No permission to delete this user.': 'No permission to delete this user.',
    'Min. 8 chars, uppercase, number & special char.':
      'Min. 8 chars, uppercase, number & special char.',
    'Please login first.': 'Please login first.',
    'Permission required.': 'Permission required.',
    'Missing api key.': 'Missing API key.',
    'Invalid api key.': 'Invalid API key.',
    'Email addresses do not match.': 'Email addresses do not match.',
    'Passwords do not match.': 'Passwords do not match.',
    'Invalid file type (max. size 10mb.)':
      'Invalid file type (max. size 10mb.)',
  },

  de: {
    // UI Components
    Delete: 'Löschen',
    Cancel: 'Abbrechen',
    Add: 'Hinzufügen',
    Export: 'Exportieren',
    Edit: 'Bearbeiten',
    Delete: 'Löschen',
    Search: 'Suchen',
    'Show more': 'Mehr anzeigen',
    'Show less': 'Weniger anzeigen',
    'rotation-warning':
      'Bitte drehe dein Gerät in den Hochformatmodus, um die volle Funktionalität sicherzustellen',
    'Select all': 'Alles auswählen',
    'Deselect all': 'Alles abwählen',
    'Your trial has expired': 'Deine Testversion ist abgelaufen',
    'If you need assistance, please': 'Wenn du Unterstützung benötigst, bitte',
    'contact us': 'kontaktiere uns',
    'Please contact your organization administrator.':
      'Bitte wende dich an deinen Organisationsadministrator.',

    // Notifications
    'Key successfully updated.': 'Schlüssel erfolgreich aktualisiert.',
    'Successfully updated user status.':
      'Benutzerstatus erfolgreich aktualisiert.',
    'User successfully logged out!': 'Benutzer erfolgreich abgemeldet!',
    'Welcome! Please verify your email address.':
      'Willkommen! Bitte bestätige deine E-Mail-Adresse.',
    'Welcome back!': 'Willkommen zurück!',
    'Password reset. Please check your inbox.':
      'Passwort zurückgesetzt. Bitte überprüfe dein Postfach.',
    'User already verified. Please login.':
      'Benutzer bereits verifiziert. Bitte melde dich an.',
    'Email successfully verified!': 'E-Mail erfolgreich verifiziert!',
    'Email available.': 'E-Mail verfügbar.',
    'Welcome! Feel free to look around.': 'Willkommen! Schau dich ruhig um.',
    'Entry successfully added.': 'Eintrag erfolgreich hinzugefügt.',
    'Schedule successfully updated.': 'Zeitplan erfolgreich aktualisiert.',
    'Entry successfully deleted.': 'Eintrag erfolgreich gelöscht.',
    'Organization successfully updated.':
      'Organisation erfolgreich aktualisiert.',
    'Organization successfully deleted.': 'Organisation erfolgreich gelöscht.',
    'Team successfully created.': 'Team erfolgreich erstellt.',
    'Team successfully updated.': 'Team erfolgreich aktualisiert.',
    'Team successfully deleted.': 'Team erfolgreich gelöscht.',
    'Role successfully created.': 'Rolle erfolgreich erstellt.',
    'Role successfully updated.': 'Rolle erfolgreich aktualisiert.',
    'Role successfully deleted.': 'Rolle erfolgreich gelöscht.',
    'Task successfully created.': 'Aufgabe erfolgreich erstellt.',
    'Task successfully updated.': 'Aufgabe erfolgreich aktualisiert.',
    'Task successfully deleted.': 'Aufgabe erfolgreich gelöscht.',
    'Status successfully updated.': 'Status erfolgreich aktualisiert.',
    'Time entry successfully added.': 'Zeiteintrag erfolgreich hinzugefügt.',
    'Time entry successfully updated.': 'Zeiteintrag erfolgreich aktualisiert.',
    'Entry successfully deleted.': 'Eintrag erfolgreich gelöscht.',
    'Status successfully updated.': 'Status erfolgreich aktualisiert.',
    'Entry successfully deleted.': 'Eintrag erfolgreich gelöscht.',
    'File successfully uploaded.': 'Datei erfolgreich hochgeladen.',
    'User successfully registered.': 'Benutzer erfolgreich registriert.',
    'User successfully deleted.': 'Benutzer erfolgreich gelöscht.',
    'Email successfully updated.': 'E-Mail erfolgreich aktualisiert.',
    'Permission successfully updated.':
      'Berechtigung erfolgreich aktualisiert.',
    'Password successfully updated.': 'Passwort erfolgreich aktualisiert.',
    'Note successfully updated.': 'Notiz erfolgreich aktualisiert.',
    'Time successfully updated.': 'Zeit erfolgreich aktualisiert.',
    'Basic data successfully updated.': 'Basisdaten erfolgreich aktualisiert.',
    'Messengers successfully updated.': 'Messenger erfolgreich aktualisiert.',
    'Position successfully updated.': 'Position erfolgreich aktualisiert.',

    'Something went wrong. Please try again.':
      'Etwas ist schief gelaufen. Bitte versuche es erneut.',
    'Missing email address.': 'Fehlende E-Mail-Adresse.',
    'Status object incomplete.': 'Statusobjekt unvollständig.',
    'Email already associated with an organization.':
      'E-Mail bereits mit einer Organisation verknüpft.',
    'User does not exist.': 'Benutzer existiert nicht.',
    'Please verify your email address. An email has been sent.':
      'Bitte bestätige deine E-Mail-Adresse. Eine E-Mail wurde gesendet.',
    'Username and password do not match.':
      'Benutzername und Passwort stimmen nicht überein.',
    'Verification failed. Please try again.':
      'Verifizierung fehlgeschlagen. Bitte versuche es erneut.',
    'End cannot be before start date.':
      'Ende kann nicht vor dem Startdatum liegen.',
    'Task is archived. Please reactivate.':
      'Aufgabe ist archiviert. Bitte reaktiviere sie.',
    'Task is ongoing. Please stop task first.':
      'Aufgabe läuft. Bitte stoppe die Aufgabe zuerst.',
    'Please cancel your subscription before proceeding.':
      'Bitte kündige dein Abonnement, bevor du fortfährst.',
    'Team with that name already exists.':
      'Ein Team mit diesem Namen existiert bereits.',
    'Team has associated users.': 'Team hat zugeordnete Nutzer.',
    'Role with that name already exists.':
      'Eine Rolle mit diesem Namen existiert bereits.',
    'Role has associated users.': 'Rolle hat zugeordnete Nutzer.',
    'Task with that name already exists.':
      'Eine Aufgabe mit diesem Namen existiert bereits.',
    'Missing filter parameters. Please try again.':
      'Fehlende Filterparameter. Bitte versuche es erneut.',
    'Reached max. amount of users in your plan.':
      'Maximale Anzahl an Benutzern in deinem Plan erreicht.',
    'User already part of an organization.':
      'Benutzer ist bereits Teil einer Organisation.',
    'No permission to delete this user.':
      'Keine Berechtigung, diesen Benutzer zu löschen.',
    'Min. 8 chars, uppercase, number & special char.':
      'Mind. 8 Zeichen, Großbuchstaben, Zahl & Sonderzeichen.',
    'Please login first.': 'Bitte melde dich zuerst an.',
    'Permission required.': 'Berechtigung erforderlich.',
    'Missing api key.': 'Fehlender API-Schlüssel.',
    'Invalid api key.': 'Ungültiger API-Schlüssel.',
    'Email addresses do not match.': 'E-Mail-Adressen stimmen nicht überein.',
    'Passwords do not match.': 'Passwörter stimmen nicht überein.',
    'Invalid file type (max. size 10mb.)':
      'Ungültiger Dateityp (max. Größe 10 MB.)',
  },
}

export const translate = (string) => {
  const language = store.state.language ? store.state.language : 'en'
  let str = translation[language][string]

  if (str) return str

  return string
}

export default (obj) => {
  translation.en = {
    ...translation.en,
    ...obj.en,
  }

  translation.de = {
    ...translation.de,
    ...obj.de,
  }
}
