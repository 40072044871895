<template>
  <form @submit.prevent class="box-shadow">
    <h3>{{ t('Manage your subscription') }}</h3>

    <InfoTable :subscription="subscription" />

    <Plans
      v-if="subscription.plan === 'trial'"
      @updateUrl="productLink = $event"
      :subscription="subscription"
    />

    <CheckoutBtns :productLink="productLink" :plan="subscription.plan" />
  </form>

  <InvoiceList />
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import InfoTable from '@modules/settings/components/subscription/InfoTable.vue'
import Plans from '@modules/settings/components/subscription/Plans.vue'
import CheckoutBtns from '@modules/settings/components/subscription/CheckoutBtns.vue'
import InvoiceList from '@modules/settings/components/subscription/InvoiceList.vue'

export default {
  name: 'Subscription',
  components: { InfoTable, Plans, CheckoutBtns, InvoiceList },
  setup() {
    const store = useStore()
    const productLink = ref('')

    const subscription = computed(() => {
      return store.getters.getSubscription
    })

    return {
      productLink,
      subscription,
    }
  },
}
</script>

<style lang="scss" scoped>
form {
  width: 700px;
  max-width: calc(95% - 30px);
  padding: 15px;
  border-radius: 15px;
  margin: 70px auto;

  h3 {
    padding-left: 5px;
  }
}
</style>
