<template>
  <div class="select row" @click="toggleOptions">
    <input v-if="required && !value" required class="required" />

    <span v-if="displayValue" class="value">
      {{ displayValue }}
    </span>

    <div v-if="showOptions" class="dropdown scroll-y">
      <div
        class="option highlight"
        v-if="allowNull"
        :select="!value"
        @click="$emit('update', null)"
      ></div>

      <div
        class="option row highlight"
        v-for="option in options"
        :key="option"
        :title="option"
        @click="$emit('update', option)"
        :selected="option === value || option[property] === value"
      >
        <span v-if="property" :title="t(option[property])">
          {{
            t(
              property === 'email'
                ? option[property]
                : upperCase(option[property])
            )
          }}
        </span>

        <span v-else :title="t(option)">{{ t(upperCase(option)) }}</span>
      </div>
    </div>

    <i class="fa-solid fa-caret-down" :class="{ rotate: showOptions }"></i>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { translate } from '@lib/translate.js'
import { upperCase } from '@lib/helper.js'

export default {
  name: 'Select',
  props: {
    options: { type: Array, default: [] },
    property: { type: String, default: '' },
    value: { type: String, default: '' },
    required: { type: Boolean, default: false },
    allowNull: { type: Boolean, default: true },
  },
  emits: ['update'],
  setup(props) {
    const showOptions = ref(false)

    const displayValue = computed(() => {
      if (!props.value || props.value === '') return null

      let value =
        props.property && props.value[props.property]
          ? props.value[props.property]
          : props.value

      if (props.property !== 'email') value = upperCase(value)
      return translate(value)
    })

    const toggleOptions = (event) => {
      event.stopPropagation()

      showOptions.value = !showOptions.value

      if (showOptions) {
        const options = document.querySelector('.select')
        const clickable = [
          document.getElementById('app'),
          ...document.querySelectorAll('form'),
        ]

        clickable.forEach((element) => {
          element.addEventListener('click', (e) => {
            if (!options.contains(e.target)) showOptions.value = false
          })
        })
      }
    }

    return {
      showOptions,
      displayValue,
      toggleOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.select {
  position: relative;
  cursor: pointer;
  background-color: var(--base);

  .value {
    margin: auto 0;
  }

  .dropdown {
    position: absolute;
    width: 100%;
    max-height: calc(5 * 45px);
    left: 0;
    top: 45px;
    z-index: 10;
    background-color: var(--base);
    border-radius: 7px;
    border: 1px solid var(--dark-2);

    .option {
      width: calc(100% - 2px);
      padding: 0 10px;
      height: 43px;
      max-height: 43px;
      align-items: center;
      border-top: 1px solid var(--dark-2);

      &:first-of-type {
        border-top: none;
      }

      span {
        margin: auto 0;
      }
    }
  }

  i {
    position: absolute;
    right: 10px;
    top: 25%;
    color: var(--dark-4);
  }

  .required {
    display: absolute;
    z-index: -5;
  }
}
</style>
