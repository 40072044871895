import store from '../store/index.js'

export const hasPermission = (permissions = [], params = {}) => {
  if (permissions.length <= 0) return true

  for (let permission of permissions) {
    switch (permission) {
      case 'admin':
        if (store.state.user.admin) return true
        break

      case 'hr':
        if (store.state.user.hr) return true
        break

      case 'manager':
        if (!params || !params.manager) break
        if (params.manager === store.state.user.id) return true

      case 'user':
        if (!params || !params.id) break
        if (params.id === store.state.user.id) return true
    }
  }

  return false
}
