<template>
  <div
    @mouseenter="$emit('show', true)"
    @mouseleave="$emit('show', false)"
    class="profile-dropdown box-shadow"
  >
    <router-link
      :to="`/settings/profile/${$store.state.user.id}`"
      class="link highlight row"
      @click="$emit('show', false)"
    >
      <i class="fa-solid fa-user"></i>
      <span>{{ t('Profil') }}</span>
    </router-link>

    <div to="" @click="logout" class="link highlight row">
      <i class="fa-solid fa-right-from-bracket"></i>
      <span>{{ t('Logout') }}</span>
    </div>
  </div>

  <div
    v-if="mobile && showDropdown"
    @click="$emit('show', false)"
    class="dropdown-overlay"
  ></div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ProfileDropdown',
  emits: ['show'],
  props: {
    showDropdown: { type: Boolean, default: false },
  },
  setup() {
    const store = useStore()

    const mobile = computed(() => {
      return store.state.screen.device === 'mobile'
    })

    const logout = async () => {
      store.commit('showSidebar', false)
      await store.dispatch('logout')
    }

    return {
      mobile,
      logout,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.profile-dropdown {
  position: absolute;
  width: 150px;
  top: 45px;
  right: 35px;
  background: var(--base);
  z-index: 15;
  padding: 15px;
  border-radius: 15px;

  @media #{$mobile} {
    right: 10px;
  }

  .link {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 15px;
    align-items: center;
    margin-bottom: 3px;

    i {
      margin-right: 7px;
    }
  }
}

.dropdown-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
</style>
