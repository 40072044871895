export default {
  'Welcome Back': 'Willkommen zurück',
  "Don't have an account?": 'Noch kein Account vorhanden?',
  'Register Organization': 'Organisation registrieren',
  '(min. 4 letters)': '(min. 4 Buchstaben)',
  'Already have an account?': 'Bereits ein Account?',
  'Reset Password': 'Passwort zurücksetzen',
  'Verify Account': 'Account verifizieren',
  'Verification Code': 'Verifizierungscode',
  'Account already verified?': 'Account bereits verifiziert?',
  Register: 'Registrieren',
  Password: 'Passwort',
  'Forgot password': 'Passwort vergessen',
  'Enter your email address': 'Wie ist deine E-Mail?',
  Submit: 'Senden',
  Login: 'Login',
  Organization: 'Unternehmen',
  'Min. char': 'Min. Char',
  Uppercase: 'Groß',
  Number: 'Nummer',
  Special: 'Sonderz.',
  'I have read and accept the Privacy policy':
    'Ich akzeptiere die Datenschutzerklärung',
  'I have read and accept the Terms & Conditions': 'Ich akzeptiere die AGB',
  Start: 'Start',
  Email: 'Email',
  'demo-01':
    'Bitte beachten: Alle Daten, die in einem Demokonto generiert werden, werden nicht dauerhaft gespeichert.',
  'Sign up': 'Registrieren',
  Login: 'Login',
  Language: 'Sprache',
  German: 'Deutsch',
  English: 'Englisch',
  'Legal notice': 'Impressum',
  'Contact us': 'Kontakt',
  Verify: 'Verifizieren',
  'Select language': 'Sprache wählen',
}
